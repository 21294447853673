import store from '../store'
//创建全局权限指令
const lazyPlugin = {
    install (app, options) {
        app.directive('hasPermission',(el, binding) => {
            //获取按钮权限
            let btnPermissions = store.getters.btnPermissions;
            //获取页面按钮权限
            let permission = binding.value;
            //判断是否有权限
            let f = btnPermissions.some(p => {
                return p.indexOf(permission) !== -1;
            });
            if (!f) {
                //没有权限 移除Dom元素
                el.parentNode.removeChild(el);
            }
        })
    }
}
export default lazyPlugin