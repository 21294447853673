<template>
    <el-config-provider :locale="locale">
        <router-view v-if="isRouterAlive" />
    </el-config-provider>
</template>
<script>
import { onMounted, onBeforeMount, provide, nextTick, ref } from "vue";

import { ElConfigProvider } from "element-plus";
import moment from "moment";

import "moment/locale/zh-cn";
moment.locale("zh-cn");

import store from "@/store/index";
import zhCn from "element-plus/es/locale/lang/zh-cn";
const { body } = document;
// h5适配
const WIDTH = 992; // refer to Bootstrap's responsive design
export default {
    components: {
        [ElConfigProvider.name]: ElConfigProvider,
    },
    setup() {
        let isRouterAlive = ref(true);
        let isMobile = null;
        const getMobile = () => {
            const rect = body.getBoundingClientRect();
            isMobile = rect.width - 1 < WIDTH;
            if (isMobile) {
                store.dispatch("app/toggleDevice", "mobile");
            } else {
                store.dispatch("app/toggleDevice", "desktop");
            }
        };
        const resizeHandler = () => {
            if (!document.hidden) {
                const isMobiles = isMobile;
                store.dispatch(
                    "app/toggleDevice",
                    isMobiles ? "mobile" : "desktop"
                );

                if (isMobiles) {
                    store.dispatch("app/closeSideBar", {
                        withoutAnimation: true,
                    });
                }
            }
        };
        const reload = () => {
            isRouterAlive.value = false;
            nextTick(() => {
                isRouterAlive.value = true;
            });
        };
        onBeforeMount(() => {
            window.addEventListener("resize", resizeHandler());
        });
        onMounted(() => {
            getMobile();
        });
        let locale = zhCn;
        provide("reload", reload);
        return {
            isMobile,
            locale,
            isRouterAlive,
            reload,
        };
    },
};
</script>

<style lang="less">
</style>
