import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/rem.ts'

import ElementPlus from 'element-plus';

import 'element-plus/theme-chalk/index.css';
import './styles/index.scss';
import './styles/common/base.css';
import * as ElIcon from '@element-plus/icons-vue'

import '../public/ueditor/ueditor.config.js'
import '../public/ueditor/ueditor.all.js'
import '../public/ueditor/lang/zh-cn/zh-cn.js'
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import https from './utils/http'
import * as filter from "./utils/filter"
import { getDicts } from "./api/dict/data"
// 自定义指令
import instruct from './utils/instruct'

import {DICT_TYPE, getDictDataLabel, getDictDatas, getDictDatas2} from "./utils/sysRuoyi/dict"
import { parseTime, resetForm, addDateRange, selectDictLabel, download, handleTree } from "./utils/sysRuoyi/ruoyi"

const app = createApp(App)

app.config.globalProperties.$filter = filter
Object.keys(ElIcon).forEach((key) => {
	app.component(key, ElIcon[key])
})


// 全局方法挂载
app.config.globalProperties.getDicts = getDicts
app.config.globalProperties.getDictDatas = getDictDatas
app.config.globalProperties.getDictDatas2 = getDictDatas2
app.config.globalProperties.DICT_TYPE = DICT_TYPE
app.config.globalProperties.getDictDataLabel = getDictDataLabel
app.config.globalProperties.parseTime = parseTime
app.config.globalProperties.resetForm = resetForm
app.config.globalProperties.addDateRange = addDateRange
app.config.globalProperties.selectDictLabel = selectDictLabel
app.config.globalProperties.download = download
app.config.globalProperties.handleTree = handleTree

app.config.globalProperties.msgSuccess = function(msg) {
	this.$message({ showClose: true, message: msg, type: "success" });
}

app.config.globalProperties.$equipment = function() {
	let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
	return flag;
}
app.provide('global', {
	https
})


// //创建全局权限指令
// app.directive('hasPermission', {
// 	mounted(el, binding) {
// 		//获取按钮权限
// 		let btnPermissions = store.getters.btnPermissions;
// 		btnPermissions = ['sys:dept:list']
// 		//获取页面按钮权限
// 		let permission = binding.value;
// 		//判断是否有权限
// 		let f = btnPermissions.some(p => {
// 			return p.indexOf(permission) !== -1;
// 		});
// 		if (!f) {
// 			//没有权限 移除Dom元素
// 			el.parentNode.removeChild(el);
// 		}
// 	}
// })


app.use(store)
	.use(router)
	.use(ElementPlus)
	.use(instruct)
	.mount('#app')
